<template>
    <modal ref="modalVerificado" titulo="Anular Entrega" icon="Alto" @guardar="anular()">
        <div class="row mx-0 justify-center my-3">
            <div class="col-10 text-center text-general">
                Se anulará la entrega de dinero
            </div>
        </div>
        <div class="row mx-0 justify-center">
            <div class="col-10">
                <p class="text-general f-14 pl-3">
                    Justificación
                </p>
                <el-input v-model="comentario" type="textarea" :rows="5" class="w-100" />
            </div>
        </div>
    </modal>
</template>

<script>
import entregasDineros from '~/services/almacen/entregas_dineros'
export default {
    data(){
        return {
            comentario: '',
            titulo: '',
            mensaje: '',
            id: 0
        }
    },
    methods: {
        toggle(id){
            this.id = id;
            this.$refs.modalVerificado.toggle();
        },
    
        async anular(){
            try {
                const params = {
                    id: this.id,
                    comentario: this.comentario,
                    estado: 4
                }
                const { data } = await entregasDineros.anularWeb(params)
                if (data.exito == true){
                    this.notificacion('', 'Entrega anulada correctamente', 'success')
                    this.$emit('saved')
                    this.$refs.modalVerificado.toggle();
                }
            } catch (error){
                this.error_catch(error)
            }
        },


        
    }
}
</script>